import React from "react";
import styled from "@emotion/styled";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { pathOr } from "ramda";
import { Wrapper } from "../../components/Wrapper";
import Img from "gatsby-image/withIEPolyfill";
import { getImage } from "../../helpers/pathExtractor";
import {
  PhoneOutlined,
  MailOutlined,
  PushpinOutlined,
  BankOutlined,
  IdcardOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

export default function KontaktPage({ data }) {
  const pageData = pathOr(null, ["allWordpressPage", "edges", "0", "node", "acf"], data);
  const information = pageData.contact;
  const fluid = getImage(pageData.image, "fluid");

  return (
    <Layout>
      <SEO title="Kontakt" />
      <Wrapper>
        <h1>Kontakt</h1>
        <ImageContainer>
          <Img fluid={fluid} />
          <div className="description">SD Mihovil - sjedište</div>
        </ImageContainer>
        <div css={{ margin: "0 auto" }}>
          <Row>
            <Column>
              <Content>
                <PushpinOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">Adresa</span>
                  <span>{information.address}</span>
                </Column>
              </Content>
            </Column>
            <Column>
              <Content>
                <MailOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">E-mail</span>
                  <span>{information.email}</span>
                </Column>
              </Content>
            </Column>
          </Row>
          <Row>
            <Column>
              <Content>
                <PhoneOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">Telefon</span>
                  <span>{information.telephone}</span>
                </Column>
              </Content>
            </Column>
            <Column>
              <Content>
                <Column>
                  <span className="headline">Naziv</span>
                  <span>{information.name}</span>
                </Column>
              </Content>
            </Column>
          </Row>
          <Row>
            <Column>
              <Content>
                <BankOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">IBAN</span>
                  <span>{information.iban}</span>
                </Column>
              </Content>
            </Column>
            <Column>
              <Content>
                <Column>
                  <span className="headline">Registracijski broj</span>
                  <span>{information.registration_number}</span>
                </Column>
              </Content>
            </Column>
          </Row>
          <Row>
            <Column>
              <Content>
                <IdcardOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">OIB</span>
                  <span>{information.oib}</span>
                </Column>
              </Content>
            </Column>
            <Column>
              <Content>
                <Column>
                  <span className="headline">MB</span>
                  <span>{information.mb}</span>
                </Column>
              </Content>
            </Column>
          </Row>
          <Row>
            <Column>
              <Content>
                <Column>
                  <span>
                    <span className="headline">Predsjednik:</span>{" "}
                    {information.administration.president}
                  </span>
                  <span>
                    <span className="headline">Podpredsjednik:</span>{" "}
                    {information.administration.vice_president}
                  </span>
                  <span>
                    <span className="headline">Tajnik:</span> {information.administration.secretary}
                  </span>
                </Column>
              </Content>
            </Column>
            <Column>
              <Content>
                <SolutionOutlined style={{ fontSize: 35 }} />
                <Column>
                  <span className="headline">Broj članova</span>
                  <span>{information.member_count}</span>
                </Column>
              </Content>
            </Column>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  {
    allWordpressPage(filter: { path: { eq: "/kontakt/" } }) {
      edges {
        node {
          id
          title
          acf {
            contact {
              address
              email
              telephone
              name
              iban
              oib
              mb
              member_count
              registration_number
              administration {
                president
                vice_president
                secretary
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Column = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  .headline {
    font-weight: bold;
  }
`;

const ImageContainer = styled.div`
  text-align: center;

  .description {
    text-align: center;
    font-weight: 300;
    font-size: 13px;
  }
`;
